<template>
<app-modal-add-product
    :show="showModalAddProduct"
    @close="showModalAddProduct = false"
    @refresh="getListProducts"
  />

     <!-- Modal Active Business-->
  <app-modal
    v-if="hasActiveBusiness"
    :show="showModalActive"
    @close="showModalActive = false"
    :textButtonCancel="$t('payment.info.active_later')"
  >
    <template v-slot:title>{{
      $t("payment.info.active_your_account_title")
    }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("product.active_your_account_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="$router.push({ name: 'business' })"
        @click="showModalActive = false"
        :showf70Icon="false"
        :primary="false"
        class="
          sm:ml-3 sm:w-auto
          bg-primary
          hover:bg-primary-100
          text-white
          outline-none
        "
      >
        {{ $t("payment.info.active_account") }}
      </app-button>
    </template>
  </app-modal>

  <app-modal
    :show="showDeleteConfirmation"
    @close="showDeleteConfirmation = false"
  >
    <template v-slot:title>{{ $t("product.modal.delete_product") }}</template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("product.modal.delete_desc") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @onClick="deleteProduct(selectedProduct)"
        :showf70Icon="false"
        :primary="false"
        type="button"
        class="
          sm:ml-3 sm:w-auto
          bg-error
          hover:bg-error
          text-white
          outline-none
        "
      >
        {{ $t("general.delete") }}
      </app-button>
    </template>
  </app-modal>

  <div class="container mx-auto">

    <app-table
      :apiResponse="apiPaginationResponse.data"
      :loading="apiPaginationLoading"
      @params-changed="getListProducts"
      @empty-button-clicked="createProduct"
      emptyIcon="app-icon-empty-product"
      :emptyTitle="$t('product.empty.title')"
      :emptyButton="$t('product.add_new_product_button')"
      :emptyDescription="$t('product.empty.description')"
      :filters="[
        {
          key: 'filter[name]',
          type: 'text',
          placeholder: $t('product.filter.name_placeholder'),
        },
        {
          key: 'filter[currency]',
          type: 'text',
          placeholder: $t('product.filter.curency_placeholder'),
        },
        {
          key: 'filter[price]',
          type: 'number',
          placeholder: $t('product.filter.price_placeholder'),
        },
        {
          key: 'filter[created_at_between]',
          type: 'date',
        },
      ]"
    >
      <template v-slot:title>
        <div>
          <p class="font-medium">
            {{ $t("layout.navbar.product") }}
          </p>
        </div>
      </template>
      <template v-slot:button>
        <app-button
          width="w-1/8"
          :showf70Icon="false"
          @click="createProduct"
          :loading="apiPaginationLoading"
        >
          <template v-slot:icon>
            <app-icon-outline name="PlusIcon" class="h-6 w-6 text-white mr-2" />
          </template>
          {{ $t("product.add_new_product_button") }}
        </app-button>
      </template>
      <template v-slot:header>
        <th class="font-bold">{{ $t("product.table_header.image") }}</th>
        <th class="font-bold">{{ $t("product.table_header.name") }}</th>
        <th class="font-bold">{{ $t("product.table_header.price") }}</th>
        <th class="font-bold">{{ $t("product.table_header.date_created") }}</th>
        <th class="font-bold">{{ $t("general.actions") }}</th>
      </template>
      <template v-slot:body="data">
        <td>
          <app-image
            v-if="getProductPhoto(data.model)"
            :src="getProductPhoto(data.model)"
            class="rounded-md flex-none w-12"
          />
          <img
            v-else
            class="w-12"
            src="@/assets/image/default_product_image.svg"
          />
        </td>
        <td>
          {{ data.model.name }}
        </td>
        <td>
          <p class="font-medium">
            {{
              $formats.currency(data.model.currency, data.model.price ?? 0.0)
            }}
          </p>
        </td>
        <td>
          {{ $moment(data.model.created_at).format("DD MMM YYYY ") }}
        </td>
        <td>
          <div class="flex space-x-2 text-gray-400">
            <div class="flex space-x-2">
              <p @click.stop="onClickEdit(data.model)">
                {{ $t("general.edit") }}
              </p>
              <p @click.stop="showModalDeleteConfirmation(data.model)">
                {{ $t("general.delete") }}
              </p>
            </div>
          </div>
        </td>
      </template>
    </app-table>
  </div>
</template>

<script>
import MEDIA_CATEGORY from "@/utils/const/media_category";
import Business_status from "@/utils/const/business_status";

export default {
  data() {
    return {
      filters: {},
      showModalAddProduct: false,
      showModalActive: false,
      selectedProduct: null,
      showDeleteConfirmation: false,
    };
  },

  mounted() {
    this.$store.dispatch("productStore/retrieveProduct", this.filters);
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["productStore/apiPaginationResponse"];
    },
    apiPaginationLoading() {
      return this.$store.getters["productStore/apiPaginationLoading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    hasActiveBusiness() {
      if (this.business.business_status_id == null) {
        return false;
      }
      return this.business.business_status_id != Business_status.APPROVED;
    },
  },

  methods: {
    getListProducts(filters) {
      this.filters = filters;
      this.$store.dispatch("productStore/retrieveProduct", this.filters);
    },

    getProductPhoto(product) {
      const url = product?.media?.find(
        (media) => media.collection_name == MEDIA_CATEGORY.PHOTO
      )?.original_url;
      return url && url.startsWith("/")
        ? `${this.$store.$backendURL}${url}`
        : url;
    },

    createProduct(){
      this.hasActiveBusiness ? this.onClickHasActiveBusiness() : this.showModalAddProduct()
    },

     onClickHasActiveBusiness(){
      this.showModalActive = !this.showModalActive;
      this.showModalAddProduct = !this.showModalAddProduct;
    },

    openModalAddNewProduct() {
      this.$store.commit("productStore/clearProduct");
      this.showModalAddProduct = !this.showModalAddProduct;
    },

    async deleteProduct(product) {
      this.showDeleteConfirmation = false;

      const result = await this.$store.dispatch(
        "productStore/deleteProduct",
        product
      );
      if (!this.$lodash.isNil(result)) {
        this.getListProducts();
      }
    },

    showModalDeleteConfirmation(product) {
      this.selectedProduct = product;
      this.showDeleteConfirmation = true;
    },

    async onClickEdit(product) {
      const result = await this.$store.dispatch(
        "productStore/getProductById",
        product._id
      );

      if (!this.$lodash.isNil(result)) {
        this.showModalAddProduct = true;
      }
    },
  },
};
</script>